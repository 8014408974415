
import React from 'react'
import styled from 'styled-components'

const DiscordPanel = styled.div`
position: fixed;
bottom:40px;
right:40px;
padding:30px;
z-index:3;
width:200px;
text-align:center;
background: ${({ theme }) => theme.bg1};
box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
  0px 24px 32px rgba(0, 0, 0, 0.01);
border-radius: 8px;
/* padding: 1rem; */
`

export default function DiscordSupport() {
    return (
        <DiscordPanel>
            <a href="https://discord.gg/UBK9YVdCxF" >
                Join the discord if you need any help ⚠️
            </a>
        </DiscordPanel>
    )
}