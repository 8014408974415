import React, { useState, useContext } from 'react'
import StakeLpRow from './components/stake-lp-row'
import StakeLpRowUnlock from './components/stake-lp-row-unlock'
import StakeDaiSection from './components/stake-dai-section'
import StakeWFTMSection from './components/stake-wftm-section'
import { useActiveWeb3React } from 'hooks'
import { useWalletModalToggle } from 'state/application/hooks'
import StakeUSDCSection from './components/stake-usdc-section'
import Modal from "../../components/Modal"
import StakeWFTMUNLOCKSection from './components/stake-wftm-unlock-section'
import StakeDAIUNLOCKSection from './components/stake-dai-unlock-section copy'
import styled, { ThemeContext } from 'styled-components'
import Switch from 'react-switch'
import { Tooltip } from '@blockstack/ui'
import { StyledIcon } from 'components/styled-icon'
import { Stack } from '@blockstack/ui'
import StakeUSDCUNLOCKSection from './components/stake-usdc-unlock-section'

export default function Stake() {
    const theme = useContext(ThemeContext)
    const [adStatus, setAdStatus] = useState(2);
    const { account } = useActiveWeb3React()
    const toggleWalletModal = useWalletModalToggle()

    const [lockPage, setLockPage] = useState(true);
    const switchLockPage = () => {
        setLockPage(!lockPage);
    }

    return (
        <div className="w-full min-h-screen bg-gray-100 dark:bg-zinc-900">
            <div className="md:px-4 mx-auto max-w-7xl lg:px-8">
                <main className="relative flex-1 py-8">
                    <StakeDaiSection apy={18.5} dailyapy={0.05} />
                    <StakeDAIUNLOCKSection apy={32.85} dailyapy={0.09} />
                    <StakeWFTMSection apy={320} dailyapy={0.87} />
                    <StakeWFTMUNLOCKSection apy={990} dailyapy={2.71} />
                    <StakeUSDCSection apy={554} dailyapy={1.51} />
                    <StakeUSDCUNLOCKSection apy={164} dailyapy={0.45} />
                    <section className="relative mt-8 overflow-hidden">
                        <header className="pb-5 border-b border-gray-200 dark:border-zinc-600">
                            <h3 className="text-lg leading-6 text-gray-900 font-headings dark:text-zinc-50">
                                Zero fees pairs liquidity pools
                            </h3>
                            <p className="max-w-3xl mt-2 text-sm text-gray-500 dark:text-zinc-300">
                                Earn PUMPKIN by providing liquidity to our zero fees pairs , you can use the PUMPKIN you earn with over
                                10 Defi platforms. <br />
                                Happy farming
                            </p>
                        </header>
                        <div className='md:px-4 mx-auto max-w-7xl lg:px-8 relative  mt-8 "'>
                            <StyledSwitchDeposit>
                                <Switch
                                    onChange={switchLockPage}
                                    checked={!lockPage}
                                    onColor={theme.primary1}
                                    onHandleColor={theme.secondary1}
                                    offColor={theme.bg4}
                                    offHandleColor="#8f929a"
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    handleDiameter={22}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    height={16}
                                    width={40}
                                />
                                <span style={{ color: theme.text1 }}>{lockPage ? "locked" : "flexible"}</span>
                            </StyledSwitchDeposit>
                        </div>
                        {
                            lockPage ? (<div>
                                {account ? (
                                    <div className="flex flex-col mt-4">
                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <div className="overflow-hidden border border-gray-200 rounded-lg dark:border-zinc-700">
                                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-zinc-600">
                                                        <thead className="bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-80">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    LP Token
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    <p className="text-sm leading-6 text-gray-500 dark:text-zinc-400 md:mb-1 inline-flex items-center">
                                                                        APY
                                                                        <Tooltip
                                                                            className="z-10"
                                                                            shouldWrapChildren={true}
                                                                            label={`Yearly / Daily`}
                                                                        >
                                                                            <StyledIcon as="QuestionMarkCircleIcon" size={5} className="block text-gray-400" />
                                                                        </Tooltip>
                                                                    </p>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Lock Time
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Staked
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Rewards
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Actions
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>

                                                        <StakeLpRow
                                                            currencyIdA="0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
                                                            currencyIdB="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
                                                            apr={240}
                                                            aprDaily={0.65}
                                                            lockTime={"7 days"}
                                                        />
                                                        <StakeLpRow
                                                            currencyIdA="0xAD522217E64Ec347601015797Dd39050A2a69694"
                                                            currencyIdB="0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83"
                                                            apr={440}
                                                            aprDaily={1.2}
                                                            lockTime={"10 days"}
                                                        />
                                                        {/* <StakeLpRow
              currencyIdA="0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454"
              currencyIdB="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
              apr={970.24}
            />
            <StakeLpRow
              currencyIdA="0x74b23882a30290451A17c44f4F05243b6b58C76d"
              currencyIdB="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
              apr={638.25}
            /> */}
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        className="inline-flex items-center justify-center w-full px-4 py-3 mt-4 text-xl font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={toggleWalletModal}
                                    >
                                        Connect Wallet
                                    </button>
                                )}
                            </div>) : (<div>
                                {account ? (
                                    <div className="flex flex-col mt-4">
                                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <div className="overflow-hidden border border-gray-200 rounded-lg dark:border-zinc-700">
                                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-zinc-600">
                                                        <thead className="bg-gray-50 dark:bg-zinc-800 dark:bg-opacity-80">
                                                            <tr>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    LP Token
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    <p className="text-sm leading-6 text-gray-500 dark:text-zinc-400 md:mb-1 inline-flex items-center">
                                                                        APY
                                                                        <Tooltip
                                                                            className="z-10"
                                                                            shouldWrapChildren={true}
                                                                            label={`Yearly / Daily`}
                                                                        >
                                                                            <StyledIcon as="QuestionMarkCircleIcon" size={5} className="block text-gray-400" />
                                                                        </Tooltip>
                                                                    </p>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    <div className="text-sm leading-6 text-gray-500 dark:text-zinc-400 inline-flex items-center">
                                                                        Boost
                                                                        <Tooltip
                                                                            className="z-10"
                                                                            shouldWrapChildren={true}
                                                                            label={`This boost is for domains holders`}
                                                                        >
                                                                            <StyledIcon as="QuestionMarkCircleIcon" size={5} className="block text-gray-400" />
                                                                        </Tooltip>
                                                                    </div>
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Fee
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Staked
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Rewards
                                                                </th>
                                                                <th
                                                                    scope="col"
                                                                    className="px-6 py-3 w-1/5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase dark:text-zinc-400"
                                                                >
                                                                    Actions
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <StakeLpRowUnlock
                                                            currencyIdA="0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
                                                            currencyIdB="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
                                                            apr={330}
                                                            aprDaily={0.9}
                                                            lockTime={"0 days"}
                                                            boost={0.93}
                                                            fee={1}
                                                        />
                                                        <StakeLpRowUnlock
                                                            currencyIdA="0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
                                                            currencyIdB="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
                                                            apr={0}
                                                            aprDaily={0}
                                                            lockTime={"0 days"}
                                                            boost={0}
                                                            fee={0}
                                                        />
                                                        <StakeLpRowUnlock
                                                            currencyIdA="0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
                                                            currencyIdB="0xAD522217E64Ec347601015797Dd39050A2a69694"
                                                            apr={450}
                                                            aprDaily={1.23}
                                                            lockTime={"0 days"}
                                                            boost={0.77}
                                                            fee={1}
                                                        />
                                                        <StakeLpRowUnlock
                                                            currencyIdA="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
                                                            currencyIdB="0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E"
                                                            apr={240}
                                                            aprDaily={0.66}
                                                            lockTime={"0 days"}
                                                            boost={0.33}
                                                            fee={2}
                                                        />
                                                        <StakeLpRowUnlock
                                                            currencyIdA="0x04068DA6C83AFCFA0e13ba15A6696662335D5B75"
                                                            currencyIdB="0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E"
                                                            apr={0}
                                                            aprDaily={0}
                                                            lockTime={"0 days"}
                                                            boost={0}
                                                            fee={0}
                                                        />
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <button
                                        className="inline-flex items-center justify-center w-full px-4 py-3 mt-4 text-xl font-medium text-center text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={toggleWalletModal}
                                    >
                                        Connect Wallet
                                    </button>
                                )}
                            </div>)}

                        <p className="mt-4 text-sm text-gray-500 dark:text-zinc-300">
                        💡You can find the full list of projects that accept PUMPKIN as a utility token here : <a style={{ color: "blue" }} href={"https://www.pumpkin.financial/our-projects"}>https://www.pumpkin.financial/our-projects</a>
                        </p></section>

                </main>
            </div>

            <Modal isOpen={adStatus == 2} onDismiss={() => setAdStatus(1)} maxHeight={90}>
                <Stack alignContent={"center"} spacing={2} alignItems={"center"}>
                    <div><img src={"./warning.webp"} width={"750px"} height={"500px"} /></div>
                    <div style={{ paddingTop: "20px", paddingBottom: "10px", paddingInline: "10px", textAlign: "center" }}>
                        by staking your tokens you agree that <br />
                        you have fully read the documents and <br />
                        understood how the protocol works.<br />
                        Click <a href="https://docs.scaryswap.com" style={{ "color": "blue", "textDecorationLine": "underline" }}>here</a> to read the documents😊
                    </div>
                </Stack>
            </Modal>
        </div >
    )
}

const StyledSwitchDeposit = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 5px;
  }
`