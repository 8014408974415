import axios from 'axios'
import { LpInterface } from '.';

export interface LpTokens {
    [key: string]: LpInterface
}

export const PUMPKIN = "0xad522217e64ec347601015797dd39050a2a69694";
export const rewardTokenSymbol = "PUMPKIN";
export const rewardTokenDecimals = 18;

export let PUMPKIN_PRICE = '1';

export const getPrice = async (token: string): Promise<string> => {
    try {
        let price = PUMPKIN_PRICE;
        const info = await axios({
            method: 'get',
            url: `https://api.dexscreener.com/latest/dex/tokens/${token}`,
            headers: {
                'Content-Type': 'application/json'
            }
        }) as any
        if (info.status === 200) {
            price = info.data?.pairs[0]?.priceUsd;
        }
        PUMPKIN_PRICE = price
        return Promise.resolve(price.toString())
    } catch (e) {
        return Promise.resolve('0')
    }
}

export const lpTokens: LpTokens = {
    "0": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xAD522217E64Ec347601015797Dd39050A2a69694",
        symbol: "PUMPKIN",
        decimals: 18,
        token1: "0xAD522217E64Ec347601015797Dd39050A2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "pumpkin.png",
        icon2: "wftm.png",
        depositFee: 25,
        rewardIcon: "USDC.webp",
        rewardTokenName: "USDC",
        rewardTokenDecimals: 6,
        isLpToken: false,
        masterChef: "0x704e258002428ad5a8dbb433d477654404f15587",
        rewardToken: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
        source: "ScarySwap",
        sourceLink: "https://Scaryswap.com",
        minAPY: 2
    },
    "1": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xAD522217E64Ec347601015797Dd39050A2a69694",
        symbol: "PUMPKIN",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "pumpkin.png",
        icon2: "wftm.png",
        depositFee: 25,
        rewardIcon: "wftm.png",
        rewardTokenName: "WFTM",
        isLpToken: false,
        masterChef: "0x034d059190a8554f2e474bd25c369f18ac5ad3ab",
        rewardToken: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        source: "ScarySwap",
        sourceLink: "https://Scaryswap.com",
        minAPY: 0.9
    },
    "2": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0x888EF71766ca594DED1F0FA3AE64eD2941740A20",
        symbol: "SOLID",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "solid.png",
        icon2: "wftm.png",
        depositFee: 1,
        rewardIcon: "solid.png",
        rewardTokenName: "solid",
        isLpToken: false,
        masterChef: "0xe26f2ee0e08b79d74038851394b2edf625609bc7",
        rewardToken: "0x888EF71766ca594DED1F0FA3AE64eD2941740A20",
        source: "0xdao",
        sourceLink: " https://www.oxdao.fi/",
        minAPY: 440
    },
    "3": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475",
        symbol: "SCREAM",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "scream.png",
        icon2: "wftm.png",
        depositFee: 1,
        rewardIcon: "pumpkin.png",
        rewardTokenName: "pumpkin",
        isLpToken: false,
        masterChef: "0xc35cbc7964d1ec52e23d87551bc0198bd6ca5684",
        rewardToken: "0xAD522217E64Ec347601015797Dd39050A2a69694",
        source: "ScarySwap",
        sourceLink: "https://Scaryswap.com",
        minAPY: 84
    },
    "4": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0x82f0B8B456c1A451378467398982d4834b6829c1",
        symbol: "MIM",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "mim.svg",
        icon2: "wftm.png",
        depositFee: 10,
        rewardIcon: "mim.svg",
        rewardTokenName: "mim",
        isLpToken: false,
        masterChef: "0x2a2fbb49ddab99a8bdfc203b70570187573ba8ac",
        rewardToken: "0x82f0B8B456c1A451378467398982d4834b6829c1",
        source: "SpiritSwap",
        sourceLink: "https://www.spiritswap.finance/farms",
        minAPY: 10.08
    },
    "5": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xE992bEAb6659BFF447893641A378FbbF031C5bD6",
        symbol: "WIGO",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "wigo.png",
        icon2: "wftm.png",
        depositFee: 1,
        rewardIcon: "wigo.png",
        rewardTokenName: "wigo",
        isLpToken: false,
        masterChef: "0xE5E1e21B7564D6DCd36d5dC061A06eD3E97c2058",
        rewardToken: "0xE992bEAb6659BFF447893641A378FbbF031C5bD6",
        source: "WigoSwap",
        sourceLink: "https://wigoswap.io/bank",
        minAPY: 7.19
    },
    "6": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xAD522217E64Ec347601015797Dd39050A2a69694",
        symbol: "PUMPKIN",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "pumpkin.png",
        icon2: "wftm.png",
        depositFee: 10,
        rewardIcon: "pumpkin.png",
        rewardTokenName: "pumpkin",
        isLpToken: false,
        masterChef: "0x6286fC97636C79ce3Cf5cf176054C69b6FFD4333",
        rewardToken: "0xAD522217E64Ec347601015797Dd39050A2a69694",
        source: "ScarySwap",
        sourceLink: " https://app.scaryswap.com/#/stake",
        minAPY: 0.6
    },
    "7": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
        symbol: "EQUAL",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "rqual.png",
        icon2: "wftm.png",
        depositFee: 1,
        rewardIcon: "rqual.png",
        rewardTokenName: "rqual",
        isLpToken: false,
        masterChef: "0x1981a9fe49A69e1050D07CcF85B65b8aB0adFDA4",
        rewardToken: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
        source: "Equalizer",
        sourceLink: "https://equalizer.exchange/liquidity",
        minAPY: 2.4
    },
    "8": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
        symbol: "TOMB",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "tomb.webp",
        icon2: "wftm.png",
        depositFee: 1,
        rewardIcon: "tomb.webp",
        rewardTokenName: "tomb",
        isLpToken: false,
        masterChef: "0x088A14e3BC62313C8D694424015657aEdD98Df26",
        rewardToken: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
        source: "Tomb",
        sourceLink: "https://tomb.com/mausoleum",
        minAPY: 15
    },
    "9": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44",
        symbol: "DEUS",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "deus.webp",
        icon2: "wftm.png",
        depositFee: 10,
        rewardIcon: "deus.webp",
        rewardTokenName: "DEUS",
        isLpToken: false,
        masterChef: "0x421ace6888c80bf5214424c71879ff69f874553c",
        rewardToken: "0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44",
        source: "deus finance",
        sourceLink: "https://deus.finance/",
        minAPY: 7.6
    },
    "10": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e",
        symbol: "BEETS",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "beets.webp",
        icon2: "wftm.png",
        depositFee: 1,
        rewardIcon: "beets.webp",
        rewardTokenName: "BEETS",
        isLpToken: false,
        masterChef: "0xd79666d97779c3bdedc5e8697f48a0849ea0a9b2",
        rewardToken: "0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e",
        source: "beethoven",
        sourceLink: "https://beets.fi/",
        minAPY: 2.7
    },
    "11": {
        dexUrl: "https://app.scaryswap.com/#/add/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        removeUrl: "https://app.scaryswap.com/#/remove/ETH/0xAD522217E64Ec347601015797Dd39050A2a69694",
        stakingToken: "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
        symbol: "DAI",
        decimals: 18,
        token1: "0xad522217e64ec347601015797dd39050a2a69694",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "PUMPKIN",
        symbol2: "WFTM",
        decimals1: 18,
        decimals2: 18,
        icon1: "dai.png",
        icon2: "wftm.png",
        depositFee: 25,
        rewardIcon: "dai.png",
        rewardTokenName: "DAI",
        isLpToken: false,
        masterChef: "0x1a2e03f8b988fb73981d1d22bFbdFa1De181CE16",
        rewardToken: "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
        source: "ScarySwap",
        sourceLink: " https://app.scaryswap.com/#/stake",
        minAPY: 7,
        isNonDynamic: true
    },
    "12": {
        dexUrl: "/add/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
        removeUrl: "/remove/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
        stakingToken: "0x1e8e53b03684a8b21f3035456a195676f762f9ec",
        symbol: "DAI",
        decimals: 18,
        token1: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
        token2: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
        symbol1: "USDC",
        symbol2: "WFTM",
        decimals1: 6,
        decimals2: 18,
        icon1: "USDC.webp",
        icon2: "wftm.png",
        depositFee: 10,
        rewardIcon: "pumpkin.png",
        rewardTokenName: "pumpkin",
        isLpToken: true,
        masterChef: "0x64e3c3fbc549c19605ba0b5d823d3ae673c2f8ab",
        rewardToken: "0xad522217e64ec347601015797dd39050a2a69694",
        source: "ScarySwap",
        sourceLink: " https://app.scaryswap.com/#/stake",
        minAPY: 39,
        isNonDynamic: false
    }
}



getPrice(lpTokens["0"].stakingToken)