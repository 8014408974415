import React, { useContext, useEffect, useMemo, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract, ethers } from 'ethers'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import styled, { ThemeContext } from 'styled-components'
import Switch from 'react-switch'
import Select from 'react-select'
import noFarm from '../../assets/images/favicon.png'
import { getPrice, lpTokens, PUMPKIN } from './constant'
import ABI from './contract/abis.json'
import FarmItem from './FarmItem'
import Modal from 'components/Modal'
import { Stack } from '@blockstack/ui'
import axios from 'axios'
import FarmItem_non_dynamic from './FarmItem_non_dynamic'

export interface LpInterface {
    stakingToken: string
    symbol: string
    decimals: number
    token1: string
    token2: string
    symbol1: string
    symbol2: string
    decimals1: number
    decimals2: number
    icon1: string
    icon2: string
    depositFee: number
    dexUrl: string
    removeUrl: string
    rewardToken: string
    rewardTokenName: string
    rewardIcon: string
    rewardTokenDecimals?: number | null
    isLpToken: boolean
    masterChef: string
    source: string
    sourceLink: string
    minAPY: number
    isNonDynamic?: boolean
}

interface FarmingStatus {
    expandItems: { [key: string]: boolean }
    showItems: { [key: string]: boolean }
    deposited: boolean
    lpSupplys: { [key: string]: BigNumber }
    loading: boolean
    searchInput: string
    sort: string
    lps: { [key: string]: LpInterface | any }
    nondynamic: boolean
}

const UseTVL = () => {
    const [tvl, setTVL] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get("https://api.llama.fi/updatedProtocol/scaryswap").then(res => {
            setLoading(false);
            setTVL(res?.data?.currentChainTvls?.Fantom)
        }).catch((err: any) => {
            setLoading(false);
            setError(err.message)
        })
    }, [])
    return useMemo(() => { return { tvl, loading, error } }, [tvl, loading, error])
}

export default function Farms() {
    const [adStatus, setAdStatus] = React.useState(2);
    const theme = useContext(ThemeContext)
    const simpleRpcProvider = new ethers.providers.JsonRpcProvider(
        'https://fantom-mainnet.gateway.pokt.network/v1/lb/62759259ea1b320039c9e7ac'
    )

    const { account, library } = useWeb3ReactCore<Web3Provider>()
    const allFarmTypes = [
        { value: 'all', label: 'All' },
        { value: 'APR', label: 'APR' }
    ]


    const [status, setStatus] = React.useState<FarmingStatus>({
        expandItems: {},
        showItems: {},
        deposited: false,
        lpSupplys: {},
        loading: false,
        lps: lpTokens,
        sort: allFarmTypes[0]?.value || 'all',
        searchInput: '',
        nondynamic: false
    })
    const updateStatus = (params: Partial<FarmingStatus>) => setStatus({ ...status, ...params })

    const [depositAmount, setDepositAmount] = React.useState<{ [key: string]: BigNumber }>({})
    const [apr, setApr] = React.useState<{ [key: string]: number }>({})
    const [sortedAPR, setSortedAPR] = React.useState<{ [key: string]: number }>({})
    const { tvl } = UseTVL()

    const onCheckboxChange = () => {
        updateStatus({ deposited: !status.deposited })
    }

    const onChangeDynamicChange = () => {
        updateStatus({ nondynamic: !status.nondynamic })
    }

    const onFarmTypeSelected = (_ft: any) => {
        updateStatus({ sort: _ft.value })
    }

    const search = (value: string) => {
        updateStatus({ searchInput: value })
    }

    React.useEffect(() => {
        const search = status.searchInput
        let sortLps = {} as { [key: string]: LpInterface | any }
        const searchLps = {} as { [key: string]: LpInterface | any }
        const sortAPRs = { ...apr };

        const poolLpTokens = {} as { [key: string]: LpInterface | any }
        Object.entries(lpTokens).map(([key, value]) => {
            if (status.nondynamic) {
                if (value.isNonDynamic) {
                    poolLpTokens[key] = lpTokens[key];
                }
            } else {
                if (!value.isNonDynamic) {
                    poolLpTokens[key] = lpTokens[key];
                }
            }
        })
        Object.entries(poolLpTokens).map(([key, value]) => {
            switch (status.sort) {
                case allFarmTypes[0]?.value: {
                    sortLps = poolLpTokens
                    break
                }
                case allFarmTypes[1]?.value: {
                    const sorted = Object.keys(apr).sort((key1, key2) => (Number(apr[key1]) < Number(apr[key2]) ? 1 : -1));
                    console.log("sorted apr", apr, sorted);
                    sorted.map((value, index) => {
                        if (!poolLpTokens[value]) return;
                        sortLps[index] = poolLpTokens[value]
                        sortAPRs[index] = apr[value]
                    })
                    break
                }
                default: {
                    sortLps = poolLpTokens
                    break
                }
            }
        })
        Object.entries(sortLps).map(([key, value]) => {
            if (
                value.symbol.toLowerCase().indexOf(search.toLowerCase()) > -1 &&
                (status.deposited ? BigNumber.from(depositAmount[key] || 0)?.gt(BigNumber.from(0)) : true)
            ) {
                searchLps[key] = value
            }
        })
        setSortedAPR(sortAPRs)
        updateStatus({ lps: searchLps })
    }, [status.searchInput, status.deposited, status.sort, status.nondynamic, depositAmount])

    const getGlobalData = async () => {
        try {
            const data = localStorage.getItem('scaryswap-gb') || '{}'
            const { tvl, apr, sum, depositAmount } = JSON.parse(data)
            if (!apr) return;
            setApr(apr || {})
            setDepositAmount(depositAmount || {})
        } catch (err) {
            console.log(err)
        }
    }

    const setGlobalData = async () => {
        try {
            const _tvl = {} as { [key: string]: number }
            const entr = Object.entries(lpTokens)
            const _apr = {} as { [key: string]: number }
            const _deposited = {} as { [key: string]: BigNumber }
            let _sum = 0
            for (let i = 0; i < entr.length; i++) {
                const [key, value] = entr[i]
                const price = await getPrice(value.stakingToken)
                const rewardTokenPrice = await getPrice(value.rewardToken)
                const masterChefContract = new Contract(value.masterChef, ABI.MasterChefV2, simpleRpcProvider)
                masterChefContract.balanceOf(account).then((balance: BigNumber) => {
                    _deposited[key] = balance
                }).catch((err: any) => {
                    console.log("balance error", err.message);
                })
                const rate = (await masterChefContract.rewardRate()) || BigNumber.from(0)
                const duration = 86400 * 365
                if (value.isLpToken) {
                    try {
                        let lpPrice = 0;
                        try {
                            const token1Price = await getPrice(value.token1)
                            const stakingTokenContract = new Contract(value?.stakingToken, ABI.PairContract, simpleRpcProvider)
                            const reserves = await stakingTokenContract.getReserves()
                            const token0 = await stakingTokenContract.token0()
                            const pumpkinAmount = String(token0).toUpperCase() == (value.token1).toUpperCase() ? reserves[0] : reserves[1]
                            const lockedTotalPrice = Number(ethers.utils.formatEther(pumpkinAmount).toString()) * Number(token1Price) * 2
                            const LPSupply = (await stakingTokenContract.totalSupply()) || BigNumber.from(0)
                            lpPrice = lockedTotalPrice / Number(ethers.utils.formatEther(LPSupply))
                        } catch (err: any) {
                            console.log("get LP price error", err.message);
                        }

                        const totalSupply = (await masterChefContract.totalSupply()) || BigNumber.from(0)
                        const totalRewards = (Number(ethers.utils.formatUnits(rate, value.rewardTokenDecimals || 18)) || 0) * duration
                        const rewardLpValue = totalRewards * Number(rewardTokenPrice)
                        const lockedValue = Number(ethers.utils.formatUnits(totalSupply)) * Number(lpPrice)
                        _tvl[key] = lockedValue
                        _apr[key] = (rewardLpValue / lockedValue) * 100
                        _sum += lockedValue;
                    } catch (err) {

                    }
                } else {
                    const totalSupply = (await masterChefContract.totalSupply()) || BigNumber.from(0)
                    const totalRewards = (Number(ethers.utils.formatUnits(rate, value.rewardTokenDecimals || 18)) || 0) * duration
                    const rewardLpValue = totalRewards * Number(rewardTokenPrice)
                    const lockedValue = Number(ethers.utils.formatUnits(totalSupply)) * Number(price)
                    _tvl[key] = lockedValue
                    _apr[key] = (rewardLpValue / lockedValue) * 100
                    _sum += lockedValue;
                    //   console.log(
                    // 	Number(ethers.utils.formatEther(totalSupply)) ,
                    //     (Number(ethers.utils.formatUnits(rate,18)) || 0),
                    //     totalRewards,
                    //     rewardTokenPrice,
                    //     rewardLpValue,
                    // 	lockedValue,
                    //     price,
                    //     Number(ethers.utils.formatUnits(totalSupply))
                    //   )
                }
            }
            localStorage.setItem(
                'scaryswap-gb',
                JSON.stringify({ tvl: _tvl, apr: _apr, sum: _sum, depositAmount: _deposited })
            )
            getGlobalData()
        } catch (e: any) {
            console.log("getGlobalData", e.message)
        }
    }

    React.useEffect(() => {
        getGlobalData()
        setGlobalData()
    }, [])

    function formatBytes(number: any, digits = 4): string {
        try {
            const SI_POSTFIXES = ['', 'k', 'M', 'G', 'T', 'P', 'E', '']
            const sign = number < 0 ? '-1' : ''
            if (number < 1) return Number(number).toFixed(digits)
            const absNumber = Math.abs(number) || 0
            const tier = (Math.log10(absNumber) / 3) | 0
            if (tier == 0) return `${Number(absNumber.toFixed(digits))}`
            const postfix = SI_POSTFIXES[tier]
            const scale = Math.pow(10, tier * 3)
            const scaled = absNumber / scale
            const floored = Math.floor(scaled * 10) / 10
            const str = Number(floored.toFixed(digits)) || '0'
            //  str = ((/\.0$/.test(str)) ? str.substr(0, str.length - 2) : str) || '0';
            return `${sign}${str}${postfix}`
        } catch (e) {
            return '0'
        }
    }

    const noData = React.useMemo(() => {
        if (Object.keys(status.lps).length === 0) return true
        return false
    }, [status.lps, status.nondynamic])

    const setExpand = (key: string, _exp: boolean) => {
        const expandItems = status.expandItems
        expandItems[key] = _exp
        updateStatus({ expandItems })
    }

    return (
        <>
            <MainBody className="relative flex justify-center flex-1 py-12 pb-8">
                <div className="relative z-10 w-full rounded-lg">
                    <div className="flex flex-col pd">
                        <div className="pd">
                            <StyledTvl>TVL: ${formatBytes(Number(tvl))}</StyledTvl>
                            <StyleFilters>
                                {/* <StyledSwitch>
				  <StyledSwitchItem
					onClick={onChangeInactiveFilter}
					disabled={status.active}
					highlight={status.active}
				  >
					Active
				  </StyledSwitchItem>
				  <StyledSwitchItem
					onClick={onChangeInactiveFilter}
					disabled={!status.active}
					highlight={!status.active}
				  >
					Inactive
				  </StyledSwitchItem>
				</StyledSwitch> */}
                                <StyledSwitchDeposit>
                                    <Switch
                                        onChange={onCheckboxChange}
                                        checked={status.deposited}
                                        onColor={theme.primary1}
                                        onHandleColor={theme.secondary1}
                                        offColor={theme.bg4}
                                        offHandleColor="#8f929a"
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        handleDiameter={22}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={40}
                                    />
                                    <span style={{ color: theme.text1 }}>Deposited</span>
                                </StyledSwitchDeposit>
                                <StyledSwitchDeposit>
                                    <Switch
                                        onChange={onChangeDynamicChange}
                                        checked={status.nondynamic}
                                        onColor={theme.primary1}
                                        onHandleColor={theme.secondary1}
                                        offColor={theme.bg4}
                                        offHandleColor="#8f929a"
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        handleDiameter={22}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        height={16}
                                        width={40}
                                    />
                                    <span style={{ color: theme.text1 }}>{status.nondynamic ? "Non-dynamic" : "Dynamic"}</span>
                                </StyledSwitchDeposit>
                                <StyledDropdowns>
                                    <StyledDropdownItem>
                                        <span style={{ color: theme.text1 }}>Sort by</span>
                                        <StyledHeaderSelect
                                            defaultValue={allFarmTypes[0]}
                                            classNamePrefix="header-select"
                                            options={allFarmTypes}
                                            onChange={onFarmTypeSelected}
                                        />
                                    </StyledDropdownItem>
                                    <StyledDropdownItem>
                                        <span style={{ color: theme.text1 }}>Search</span>
                                        <StyledInput>
                                            <input
                                                type="text"
                                                className="search"
                                                placeholder="Search Farms"
                                                onChange={(e) => {
                                                    search(e.target.value)
                                                }}
                                                value={status.searchInput}
                                            />
                                        </StyledInput>
                                    </StyledDropdownItem>
                                </StyledDropdowns>
                            </StyleFilters>
                            <StyledBody>
                                {noData && (
                                    <StyledNoFarm>
                                        <img src={noFarm} />
                                        No yields yet
                                    </StyledNoFarm>
                                )}
                                <StyledFarmGrid>
                                    {!status.nondynamic ? Object.entries(status.lps).map(([key, value]) => (
                                        <FarmItem
                                            value={value}
                                            _key={key}
                                            key={key}
                                            onExpand={(_exp: boolean) => {
                                                setExpand(key, _exp)
                                            }}
                                            expand={status.expandItems[key]}
                                            apr={sortedAPR[key]}
                                        />
                                    )) : Object.entries(status.lps).map(([key, value]) => (
                                        <FarmItem_non_dynamic
                                            value={value}
                                            _key={key}
                                            key={key}
                                            onExpand={(_exp: boolean) => {
                                                setExpand(key, _exp)
                                            }}
                                            expand={status.expandItems[key]}
                                            apr={sortedAPR[key]}
                                        />
                                    ))
                                    }
                                </StyledFarmGrid>
                            </StyledBody>
                        </div>
                    </div>
                </div>
            </MainBody>

            <Modal isOpen={adStatus == 2} onDismiss={() => setAdStatus(1)} maxHeight={90}>
                <Stack alignContent={"center"} spacing={2} alignItems={"center"}>
                    <div><img src={"./warning.webp"} width={"750px"} height={"500px"} /></div>
                    <div style={{ paddingTop: "20px", paddingBottom: "10px", paddingInline: "10px", textAlign: "center" }}>
                        by staking your tokens you agree that <br />
                        you have fully read the documents and <br />
                        understood how the protocol works.<br />
                        Click <a href="https://docs.scaryswap.com" style={{ "color": "blue", "textDecorationLine": "underline" }}>here</a> to read the documents😊
                    </div>
                </Stack>
            </Modal>
        </>
    )
}

export const StyledBody = styled.div`
  position: relative;
`

const MainBody = styled.div`
  width: 80%;
  max-width: 1280px;
  background-color: transparent;
  .pd {
    padding: 1rem;
  }
  @media (max-width: 768px) {
    width: 100%;
    .pd {
      padding: 0.3rem;
    }
  }
`
const StyledNoFarm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  font-size: 16px;
  font-weight: normal;
  color: #8f929a;
  img {
    width: 120px;
    margin-bottom: 14px;
  }
`

const StyledFarmGrid = styled.div`
  width: 100%;
`

const StyledInput = styled.div`
  .search {
    margin: 0 10px;
    width: 180px;
    border: 1px solid ${({ theme }) => theme.text4};
    border-radius: 8px;
    background-color: transparent;
    color: ${({ theme }) => theme.text1};
  }
`
const StyledTvl = styled.div`
  font-size: 2rem;
  color: ${({ theme }) => theme.yellow2};
  text-align: left;
  @media (max-width: 768px) {
    text-align: center;
  }
`

const StyledFarmGridHeaderCell = styled.div`
  padding: 0px 10px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  align-items: center;
`

export const StyledHeaderSelect = styled(Select)`
  min-width: 155px;
  margin-left: 10px;
  .header-select__control {
    background-color: transparent;
    border-color: #3b3c48;
    padding: 0px 0px;
    border-radius: 6px;
    :hover {
      border-color: #717179;
    }
  }
  .header-select__control--is-focused {
    border-color: #717179;
    outline: none;
    box-shadow: none;
  }
  .header-select__single-value {
    color: ${({ theme }) => theme.secondary1};
    font-size: 15px;
    font-weight: 500;
    border-color: #3b3c48;
  }
  .header-select__menu {
    margin-top: 0;
    color: ${({ theme }) => theme.secondary1};
    font-size: 14px;
    font-weight: normal;
    background-color: ${({ theme }) => theme.bg1};
  }
  .header-select__indicator-separator {
    display: none;
  }
  .header-select__option--is-selected {
    background-color: transparent;
    color: ${({ theme }) => theme.secondary1};
  }
  .header-select__option--is-focused {
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.white};
  }
  @media (max-width: 768px) {
    min-width: 120px;
  }
  input {
    border: none;
    outline: none;
    box-shadow: none;
  }
  input:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
`

const StyledSwitchDeposit = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 5px;
  }
`

export const StyledDropdowns = styled.div`
  margin-left: auto;
  display: flex;
  @media (max-width: 768px) {
    align-items: center;
    margin-left: 0;
    display: block;
    > div {
      margin: 1rem auto;
    }
  }
`
export const StyledDropdownItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`

export const StyleFilters = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const StyledSwitch = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px #383e4b;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.bg2};
  padding: 3px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const StyledSwitchItem = styled.button<{ highlight?: boolean }>`
  border-radius: 4px;
  appearance: none;
  background-color: ${({ highlight, theme }) => (!highlight ? 'transparent' : theme.secondary1)};
  border: none;
  color: ${({ highlight, theme }) => (highlight ? theme.white : theme.text1)};
  text-transform: uppercase;
  font-weight: 500;
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: ease-in-out 150ms;
  font-size: 12px;
  text-decoration: none;
  pointer-events: ${({ highlight }) => (highlight ? 'none' : 'auto')};
  &:hover {
    color: ${({ highlight, theme }) => (highlight ? theme.white : theme.text1)};
  }
`
