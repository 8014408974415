import React, { useState, useCallback } from 'react'
import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonConfirmed, ButtonError } from '../Button'
import ProgressCircles from '../ProgressSteps'
import CurrencyInputPanel from '../CurrencyInputPanel'
import { TokenAmount, Pair } from '@uniswap/sdk'
import { useActiveWeb3React } from '../../hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useLPStakingContract } from '../../hooks/useContract'
import { useApproveCallback, ApprovalState } from '../../hooks/useApproveCallback'
import { StakingInfo, useDerivedStakeInfo } from '../../state/stake/hooks'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { LoadingView, SubmittedView } from '../ModalViews'
import { ethers } from 'ethers'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
    isOpen: boolean
    onDismiss: () => void
    stakingInfo: StakingInfo
    userLiquidityUnstaked: TokenAmount | undefined
    fee?: number
}

export default function StakingModal({ isOpen, onDismiss, stakingInfo, userLiquidityUnstaked, fee }: StakingModalProps) {
    const { library } = useActiveWeb3React()

    // track and parse user input
    const [typedValue, setTypedValue] = useState('')
    const { parsedAmount, error } = useDerivedStakeInfo(
        typedValue,
        stakingInfo?.stakedAmount.token,
        userLiquidityUnstaked
    )

    // state for pending and submitted txn views
    const addTransaction = useTransactionAdder()
    const [attempting, setAttempting] = useState<boolean>(false)
    const [hash, setHash] = useState<string | undefined>()
    const wrappedOnDismiss = useCallback(() => {
        setHash(undefined)
        setAttempting(false)
        onDismiss()
    }, [onDismiss])

    // pair contract for this token to be staked
    const dummyPair =
        stakingInfo?.tokens[0] && stakingInfo?.tokens[1]
            ? new Pair(new TokenAmount(stakingInfo?.tokens[0], '0'), new TokenAmount(stakingInfo?.tokens[1], '0'))
            : undefined

    // approval data for stake
    const deadline = useTransactionDeadline()
    const [approval, approveCallback] = useApproveCallback(parsedAmount, stakingInfo?.stakingRewardAddress)

    const stakingContract = useLPStakingContract(stakingInfo?.stakingRewardAddress)
    async function onStake() {
        setAttempting(true)
        console.log(stakingContract, parsedAmount, deadline);
        if (stakingContract && parsedAmount && deadline) {
            stakingContract
                .Stake(`0x${parsedAmount.raw.toString(16)}`, { value: ethers.utils.parseUnits(String(fee)) })
                .then((response: TransactionResponse) => {
                    addTransaction(response, {
                        summary: `Deposit liquidity`
                    })
                    setHash(response.hash)
                })
                .catch((error: any) => {
                    setAttempting(false)
                    console.log(error)
                })
        }
    }

    // wrapped onUserInput to clear signatures
    const onUserInput = useCallback((typedValue: string) => {
        setTypedValue(typedValue)
    }, [])

    // used for max input button
    const maxAmountInput = maxAmountSpend(userLiquidityUnstaked)
    const atMaxAmount = Boolean(maxAmountInput && parsedAmount?.equalTo(maxAmountInput))
    const handleMax = useCallback(() => {
        maxAmountInput && onUserInput(maxAmountInput.toExact())
    }, [maxAmountInput, onUserInput])

    async function onAttemptToApprove() {
        if (!library || !deadline) throw new Error('missing dependencies')
        const liquidityAmount = parsedAmount
        if (!liquidityAmount) throw new Error('missing liquidity amount')

        return approveCallback()
    }

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
            {!attempting && !hash && (
                <ContentWrapper gap="lg">
                    <RowBetween>
                        <TYPE.mediumHeader>Deposit</TYPE.mediumHeader>
                        <CloseIcon onClick={wrappedOnDismiss} />
                    </RowBetween>
                    <CurrencyInputPanel
                        value={typedValue}
                        onUserInput={onUserInput}
                        onMax={handleMax}
                        showMaxButton={!atMaxAmount}
                        currency={stakingInfo?.stakedAmount.token}
                        pair={dummyPair}
                        label={''}
                        disableCurrencySelect={true}
                        customBalanceText={'Available to deposit: '}
                    />

                    <RowBetween>
                        <ButtonConfirmed
                            mr="0.5rem"
                            onClick={onAttemptToApprove}
                            confirmed={approval === ApprovalState.APPROVED}
                            disabled={approval !== ApprovalState.NOT_APPROVED}
                        >
                            Approve
                        </ButtonConfirmed>
                        <ButtonError
                            disabled={!!error || approval !== ApprovalState.APPROVED}
                            error={!!error && !!parsedAmount}
                            onClick={onStake}
                        >
                            {error ?? 'Deposit'}
                        </ButtonError>
                    </RowBetween>
                    <ProgressCircles steps={[approval === ApprovalState.APPROVED]} disabled={true} />
                </ContentWrapper>
            )}
            {attempting && !hash && (
                <LoadingView onDismiss={wrappedOnDismiss}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.largeHeader>Depositing Liquidity</TYPE.largeHeader>
                        <TYPE.body fontSize={20}>{parsedAmount?.toSignificant(4)} {stakingInfo?.stakedAmount.token?.name}</TYPE.body>
                    </AutoColumn>
                </LoadingView>
            )}
            {attempting && hash && (
                <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
                        <TYPE.body fontSize={20}>Deposited {parsedAmount?.toSignificant(4)} {stakingInfo?.stakedAmount.token?.name}</TYPE.body>
                    </AutoColumn>
                </SubmittedView>
            )}
        </Modal>
    )
}
