import { BigNumber, Contract, ethers } from 'ethers';
import React, { forwardRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import TokenSliderInput from './TokenSliderInput';
import { useContract } from 'hooks/useContract';
import ABI from './contract/abis.json'
import { LpInterface } from './index'
import { NavLink as RouterLink } from 'react-router-dom'

interface InputInterface {
    _key: string
    value: LpInterface
    expand?: boolean
    onExpand: Function
    apr: number
}

interface ROIInterface {
    input: number
    inputLp: number
    stakedFor: number
    compounding: number
    output: number
}


const FarmItem: React.ForwardRefRenderFunction<unknown, InputInterface> = ({ _key, value, expand, onExpand, apr }) => {
    const theme = useContext(ThemeContext)
    const { account, library } = useWeb3ReactCore<Web3Provider>()
    const [depositAmount, setDepositAmount] = React.useState<BigNumber>(BigNumber.from(0))
    const [tokenBalance, settokenBalance] = React.useState<BigNumber>(BigNumber.from(0))
    const [input1, setInput1] = React.useState<BigNumber>(BigNumber.from(0))
    const [input2, setInput2] = React.useState<BigNumber>(BigNumber.from(0))
    const [allowance, setAllowance] = React.useState<BigNumber>(BigNumber.from(0))
    const [rewards, setRewards] = React.useState<BigNumber>(BigNumber.from(0))


    const [showApyModal, setShowApyModal] = React.useState<boolean>(false)


    const [status, setStatus] = React.useState<ROIInterface>({
        input: 0,
        inputLp: 0,
        stakedFor: 365,
        compounding: 0,
        output: 0
    })
    const updateStatus = (params: Partial<ROIInterface>) => setStatus({ ...status, ...params });

    const masterChefContract = useContract(value.masterChef, ABI.MasterChefV2, true)

    function formatBytes(number: any, digits = 3): string {
        try {
            const SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"];
            const sign = number < 0 ? '-1' : '';
            number = Number(number).toFixed(digits)
            const absNumber = Math.abs(number) || 0;
            const tier = Math.log10(absNumber) / 3 | 0;
            if (tier == 0) return `${absNumber}`;
            const postfix = SI_POSTFIXES[tier];
            const scale = Math.pow(10, tier * 3);
            const scaled = absNumber / scale;
            const floored = Math.floor(scaled * 10) / 10;
            let str = floored.toFixed(1) || '0';
            str = ((/\.0$/.test(str)) ? str.substr(0, str.length - 2) : str) || '0';
            return `${sign}${str}${postfix}`;
        } catch (e) {
            return '0'
        }
    }

    const getBalanceString = (balance: BigNumber | null, unit: number) => {
        try {
            if (!balance) return 0;
            if (!unit) unit = 18;
            return Number(ethers.utils.formatUnits(balance, unit || 18)).toFixed(2)
        } catch (error) {
            return 0;
        }
    }
    const deposit = async () => {
        try {
            if (!input1 || input1.lt(BigNumber.from(0)) || input1.eq(BigNumber.from(0))) return alert("input amount")
            if (!masterChefContract) return;
            if (tokenBalance.lt(input1)) return;
            const stakingTokenContract = new Contract(value.stakingToken, ABI.StakingTokenContract, library?.getSigner())
            // check approve
            const allowance = await stakingTokenContract.allowance(account, value.masterChef);
            if (Number(allowance) < Number(input1)) {
                const tx = await stakingTokenContract.approve(value.masterChef, input1.mul(100));
                await tx.wait();
            }
            const tx = await masterChefContract.stake(input1, { value: ethers.utils.parseEther(String(value.depositFee)) });
            await tx.wait();
            getUserInfo()
        } catch (e) {
            console.log(e)
            return ""
        }
    }

    const approve = async () => {
        try {
            if (!input1 || input1.lt(BigNumber.from(0)) || input1.eq(BigNumber.from(0))) return alert("input amount")
            if (!masterChefContract) return;
            if (tokenBalance.lt(input1)) return;
            const stakingTokenContract = new Contract(value.stakingToken, ABI.StakingTokenContract, library?.getSigner())
            // check approve
            const tx = await stakingTokenContract.approve(value.masterChef, input1.mul(100));
            await tx.wait();
            getUserInfo()
        } catch (e) {
            console.log(e)
            return ""
        }
    }
    const withdraw = async () => {
        try {
            if (!input2 || input2.lt(BigNumber.from(0)) || input2.eq(BigNumber.from(0))) return alert("input amount")
            if (!masterChefContract) return;
            const tx = await masterChefContract.withdraw(input2, { value: ethers.utils.parseEther(String(value.depositFee)) });
            await tx.wait();
            getUserInfo()
        } catch (e) {
            console.log(e)
            return ""
        }
    }

    const claimReward = async () => {
        try {
            if (!masterChefContract) return;
            const tx = await masterChefContract.getReward({ value: ethers.utils.parseEther(String(value.depositFee)) });
            await tx.wait();
            getUserInfo()
        } catch (e) {
            console.log(e)
            return ""
        }
    }

    const withdrawAll = async () => {
        try {
            if (!masterChefContract || depositAmount.eq(BigNumber.from(0))) return;
            const tx = await masterChefContract.withdraw(depositAmount);
            await tx.wait();
            getUserInfo()
        } catch (e) {
            console.log(e)
            return ""
        }
    }

    const getUserInfo = () => {
        try {
            if (masterChefContract) {
                const stakingTokenContract = new Contract(value?.stakingToken, ABI.PairContract, library)
                if (account) {
                    stakingTokenContract.balanceOf(account).then((balance: BigNumber) => {
                        settokenBalance(balance)
                    })
                    masterChefContract.balanceOf(account).then((balance: BigNumber) => {
                        setDepositAmount(balance)
                    })
                    masterChefContract.earned(account).then((balance: BigNumber) => {
                        setRewards(balance)
                    })
                    stakingTokenContract.allowance(account, value.masterChef).then((allowance: BigNumber) => {
                        setAllowance(allowance)
                    });
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const calcApy = (input: number, stake: number, compounding: number) => {
        const APR = apr; //APR = apy;
        let v = 0 as any;
        if (compounding == 0) {
            v = Number(input * (1 + ((APR / 100)) / (365 / stake))).toFixed(2);
        } else {
            v = input * (Math.pow((1 + ((APR / 100) / (365 / compounding))), (stake / 365) * (365 / compounding)))
        }
        updateStatus({ input, stakedFor: stake, compounding, output: v })
    }

    React.useEffect(() => {
        getUserInfo()
    }, [account])

    return (
        <>
            <StyledFarmGridBody>
                <StyledContainer isExpand={expand} className="bg-white rounded-lg  dark:bg-zinc-800">
                    <StyledHeader onClick={(e) => { if (e.target?.id !== "showApy") { onExpand(!expand) } }} >
                        <StyledHeaderCell>
                            <StyledHeaderIcon>
                                <StyledHeaderIconWrapper marginRight={value?.token1 ? '-10px' : ''}>
                                    <img src={`./icon/${value?.icon1}`} style={{ width: '25px', borderRadius: '50%' }} />
                                </StyledHeaderIconWrapper>
                                {value?.isLpToken && value?.token2 && (
                                    <StyledHeaderIconWrapper>
                                        <img src={`./icon/${value?.icon2}`} style={{ width: '30px', borderRadius: '50%' }} />
                                    </StyledHeaderIconWrapper>
                                )}
                            </StyledHeaderIcon>
                            <StyledHeaderStatus>
                                <StyledHeaderTitle style={{ marginLeft: value.isLpToken ? '0' : '8px' }}>
                                    {!value.isLpToken ? value?.symbol : value?.symbol1 + '/' + value?.symbol2}
                                </StyledHeaderTitle>
                            </StyledHeaderStatus>
                        </StyledHeaderCell>
                        <StyledHeaderCell hiddenXs={true}>
                            <StyledHeaderStatus>
                                <StyledHeaderSubTitle>Balance</StyledHeaderSubTitle>
                                <StyledHeaderDataValue>{getBalanceString(tokenBalance, value.decimals)}</StyledHeaderDataValue>
                            </StyledHeaderStatus>
                        </StyledHeaderCell>
                        <StyledHeaderCell hiddenXs={true}>
                            <StyledHeaderStatus>
                                <StyledHeaderSubTitle>Staked</StyledHeaderSubTitle>
                                <StyledHeaderDataValue>
                                    {getBalanceString(depositAmount, value?.decimals)}
                                </StyledHeaderDataValue>
                            </StyledHeaderStatus>
                        </StyledHeaderCell>
                        <StyledHeaderCell hiddenXs={true}>
                            <StyledHeaderStatus>
                                <StyledHeaderSubTitle>Deposit Fee</StyledHeaderSubTitle>
                                <StyledHeaderDataValue>
                                    {value.depositFee} FTM
                                </StyledHeaderDataValue>
                            </StyledHeaderStatus>
                        </StyledHeaderCell>
                        <StyledHeaderCell hiddenXs={true}>
                            <StyledHeaderDataValue>
                                <div className="line">
                                    <StyledHeaderStatus>
                                        <StyledHeaderSubTitle style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>APR
                                            <button style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={() => { setShowApyModal(true) }}>
                                                <svg id="showApy" viewBox="0 0 24 24" width="24px" fill={theme.primary3}><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"></path><path d="M11.25 7.72H6.25V9.22H11.25V7.72Z"></path><path d="M18 15.75H13V17.25H18V15.75Z"></path><path d="M18 13.25H13V14.75H18V13.25Z"></path><path d="M8 18H9.5V16H11.5V14.5H9.5V12.5H8V14.5H6V16H8V18Z"></path><path d="M14.09 10.95L15.5 9.54L16.91 10.95L17.97 9.89L16.56 8.47L17.97 7.06L16.91 6L15.5 7.41L14.09 6L13.03 7.06L14.44 8.47L13.03 9.89L14.09 10.95Z"></path></svg>
                                            </button>
                                        </StyledHeaderSubTitle>

                                        <StyledHeaderDataValue>
                                            {formatBytes(apr)}%
                                        </StyledHeaderDataValue>
                                    </StyledHeaderStatus>
                                </div>
                                {/* <div className="line">
											<span className="field">APY</span>
											<div className="value">
											0%
											</div>
									</div> */}
                            </StyledHeaderDataValue>
                        </StyledHeaderCell>
                        <StyledHeaderCell hiddenXs={true}>
                            <StyledHeaderStatus>
                                <StyledHeaderSubTitle>Stake to Earn</StyledHeaderSubTitle>
                                <StyledHeaderIconWrapper>
                                    <img src={`./icon/${value?.rewardIcon}`} style={{ width: '25px', borderRadius: '50%' }} />
                                </StyledHeaderIconWrapper>
                            </StyledHeaderStatus>
                        </StyledHeaderCell>
                        <StyledHeaderCell>
                            <StyledHeaderAction onClick={() => { onExpand(!expand) }} expanded={expand}>
                                {!expand ?
                                    <svg stroke={`${theme.text1}`} fill={`${theme.text1}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em"><path d="M8.2 275.4c0-8.6 3.4-17.401 10-24.001 13.2-13.2 34.8-13.2 48 0l451.8 451.8 445.2-445.2c13.2-13.2 34.8-13.2 48 0s13.2 34.8 0 48L542 775.399c-13.2 13.2-34.8 13.2-48 0l-475.8-475.8c-6.8-6.8-10-15.4-10-24.199z"></path></svg> :
                                    <svg stroke={`${theme.text1}`} fill={`${theme.text1}`} strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" ><path d="M8.2 751.4c0 8.6 3.4 17.401 10 24.001 13.2 13.2 34.8 13.2 48 0l451.8-451.8 445.2 445.2c13.2 13.2 34.8 13.2 48 0s13.2-34.8 0-48L542 251.401c-13.2-13.2-34.8-13.2-48 0l-475.8 475.8c-6.8 6.8-10 15.4-10 24.2z"></path></svg>
                                }
                            </StyledHeaderAction>
                        </StyledHeaderCell>
                    </StyledHeader>
                    <StyledSubheaderMobile>
                        <StyledSubheaderCell>
                            APR:
                            <StyledHeaderDataValue>
                                {formatBytes(apr)}%
                            </StyledHeaderDataValue>

                            <button style={{ cursor: 'pointer', marginLeft: '8px' }} onClick={() => { setShowApyModal(true) }}>
                                <svg id="showApy" viewBox="0 0 24 24" width="24px" fill={theme.primary3}><path d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"></path><path d="M11.25 7.72H6.25V9.22H11.25V7.72Z"></path><path d="M18 15.75H13V17.25H18V15.75Z"></path><path d="M18 13.25H13V14.75H18V13.25Z"></path><path d="M8 18H9.5V16H11.5V14.5H9.5V12.5H8V14.5H6V16H8V18Z"></path><path d="M14.09 10.95L15.5 9.54L16.91 10.95L17.97 9.89L16.56 8.47L17.97 7.06L16.91 6L15.5 7.41L14.09 6L13.03 7.06L14.44 8.47L13.03 9.89L14.09 10.95Z"></path></svg>
                            </button>
                        </StyledSubheaderCell>
                        <StyledSubheaderCell>
                            Staked:
                            <StyledHeaderDataValue highlight={true}>
                                {getBalanceString(depositAmount, value?.decimals)}
                            </StyledHeaderDataValue>
                        </StyledSubheaderCell>
                        <StyledSubheaderCell>
                            Deposit Fee:
                            <StyledHeaderDataValue highlight={true}>
                                {value.depositFee}FTM
                            </StyledHeaderDataValue>
                        </StyledSubheaderCell>
                        <StyledSubheaderCell>
                            Stake to Earn:
                            <StyledHeaderDataValue highlight={true}>
                                <StyledHeaderIconWrapper>
                                    <img src={`./icon/${value?.rewardIcon}`} style={{ width: '25px', borderRadius: '50%' }} />
                                </StyledHeaderIconWrapper>
                            </StyledHeaderDataValue>
                        </StyledSubheaderCell>
                    </StyledSubheaderMobile>
                    {expand &&
                        <StyledContent>
                            <StyledInnerContent>
                                <StyledControl>
                                    <StyledControlItem className="balance">
                                        <StyledContainer style={{ border: 'none' }}>
                                            <Balance>
                                                Balance:&nbsp;
                                                <span className="balance-click">
                                                    {getBalanceString(tokenBalance, value.decimals)}
                                                </span>
                                                &nbsp; {value?.symbol}
                                                {/* {value?.symbol2 ? '/' + value?.symbol2 : ''} */}
                                            </Balance>
                                            <TokenSliderInput
                                                hasError={false}
                                                token={value?.symbol}
                                                decimals={value?.decimals}
                                                precision={value?.decimals}
                                                onChange={(value) => { setInput1(value) }}
                                                token0={value?.symbol1}
                                                token1={value?.symbol2}
                                                maxBalance={tokenBalance}
                                                hideMax
                                            />
                                            <StyledFooter>
                                                <Button onClick={() => { approve() }} disabled={Number(allowance) >= Number(input1)}>Approve</Button>
                                                <Button onClick={() => { deposit() }} disabled={Number(allowance) < Number(input1)}>Stake</Button>
                                            </StyledFooter>
                                        </StyledContainer>
                                    </StyledControlItem>

                                    <StyledControlItem className="deposited">
                                        <StyledContainer style={{ border: 'none' }}>
                                            <Balance>
                                                Staked:&nbsp;
                                                <span className="balance-click">
                                                    {getBalanceString(depositAmount, value?.decimals)}
                                                </span>
                                                &nbsp; {value?.symbol}
                                            </Balance>
                                            <TokenSliderInput
                                                hasError={false}
                                                token={value?.symbol}
                                                decimals={value?.decimals}
                                                precision={value?.decimals}
                                                onChange={(value) => { setInput2(value) }}
                                                token0={value?.symbol1}
                                                token1={value?.symbol2}
                                                maxBalance={depositAmount}
                                                hideMax
                                            />
                                            <StyledFooter>
                                                <Button onClick={() => { withdraw() }} className="btn2">Withdraw</Button>
                                                <Button onClick={() => { withdrawAll() }} className="btn2">Withdraw all</Button>
                                            </StyledFooter>
                                        </StyledContainer>
                                    </StyledControlItem>
                                </StyledControl>
                            </StyledInnerContent>
                            <StyledClaimContainer>
                                <div className="flex">
                                    {
                                        value.isLpToken &&
                                        <StyledLiquidity>
                                            <RouterLink to={value.dexUrl}>
                                                Add liquidity
                                            </RouterLink>
                                            <RouterLink to={value.removeUrl}>
                                                Remove liquidity
                                            </RouterLink>
                                        </StyledLiquidity>
                                    }
                                    {
                                        !value.isLpToken &&
                                        <StyledLiquidity>
                                            <div>
                                                <a href={value.sourceLink} target="_blank" rel="noreferrer" style={{ fontSize: "15px" }}>
                                                    {value.source}  🔍

                                                </a>
                                            </div>
                                            <StyledMinAPY>
                                                Min Apy : {value.minAPY}%
                                            </StyledMinAPY>
                                        </StyledLiquidity>
                                    }
                                </div>
                                <div className="flex flex-wrap">
                                    <StylePendingRewards>
                                        <StyledMinAPY>
                                            Rewards: {parseFloat(Number(ethers.utils.formatUnits(rewards, value.rewardTokenDecimals || 18)).toFixed(5))} {value?.rewardTokenName}
                                        </StyledMinAPY>
                                        <Button onClick={() => { claimReward() }} style={{ marginLeft: '8px' }}>Claim reward</Button>
                                    </StylePendingRewards>
                                </div>
                            </StyledClaimContainer>
                        </StyledContent>
                    }
                </StyledContainer>
            </StyledFarmGridBody>
            {
                showApyModal && <StyledApyModal>
                    <div className="overlay"></div>
                    <div className="content">
                        <div className="flex" style={{ justifyContent: 'space-between', alignContent: 'center', alignItems: 'center', margin: '0.5rem 0 1rem 0' }}>
                            <h4 style={{ color: theme.text1, fontSize: '1.4rem' }}>ROI Calculator</h4>
                            <div style={{ cursor: 'pointer' }} onClick={() => { setShowApyModal(false) }}>
                                <svg viewBox="0 0 24 24" color="primary" width="20px" ><path fill={theme.primary1} d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"></path></svg>
                            </div>
                        </div>
                        <hr style={{ borderColor: theme.text5 }}></hr>
                        <StyledSmallText>{value.symbol} STAKED</StyledSmallText>
                        <StyledInputValue>
                            <div className="input-row">
                                <input type="number" placeholder='0.00' value={status.input} onChange={(e) => { calcApy(Number(e.target.value || 0), status.stakedFor, status.compounding) }} />
                                <p style={{ color: theme.text3 }}>USD</p>
                            </div>
                        </StyledInputValue>
                        <div className="flex" style={{ justifyContent: 'space-between' }}>
                            <StyledUSD onClick={() => { updateStatus({ input: 100 }) }}>$100</StyledUSD>
                            <StyledUSD onClick={() => { updateStatus({ input: 1000 }) }}>$1000</StyledUSD>
                            <StyledUSD onClick={() => { updateStatus({ input: Number(ethers.utils.formatUnits(tokenBalance).toString()) }) }}>MY BALANCE</StyledUSD>
                        </div>
                        <StyledSmallText>STAKED FOR</StyledSmallText>
                        <StyledStakedFor>
                            <div className={`item ${status.stakedFor == 1 ? 'active' : ''}`} onClick={() => { calcApy(status.input, 1, status.compounding) }}>1D</div>
                            <div className={`item ${status.stakedFor == 4 ? 'active' : ''}`} onClick={() => { calcApy(status.input, 4, status.compounding) }}>4D</div>
                            <div className={`item ${status.stakedFor == 30 ? 'active' : ''}`} onClick={() => { calcApy(status.input, 30, status.compounding) }}>30D</div>
                            <div className={`item ${status.stakedFor == 365 ? 'active' : ''}`} onClick={() => { calcApy(status.input, 365, status.compounding) }}>1Y</div>
                            <div className={`item ${status.stakedFor == 1826 ? 'active' : ''}`} onClick={() => { calcApy(status.input, 1826, status.compounding) }}>5Y</div>
                        </StyledStakedFor>
                        <StyledSmallText>COMPOUNDING</StyledSmallText>
                        <StyledStakedFor>
                            <div className={`item ${status.compounding == 0 ? 'active' : ''}`} onClick={() => { calcApy(status.input, status.stakedFor, 0) }}>None</div>
                            <div className={`item ${status.compounding == 1 ? 'active' : ''}`} onClick={() => { calcApy(status.input, status.stakedFor, 1) }}>1D</div>
                            <div className={`item ${status.compounding == 7 ? 'active' : ''}`} onClick={() => { calcApy(status.input, status.stakedFor, 7) }}>7D</div>
                            <div className={`item ${status.compounding == 14 ? 'active' : ''}`} onClick={() => { calcApy(status.input, status.stakedFor, 14) }}>14D</div>
                            <div className={`item ${status.compounding == 30 ? 'active' : ''}`} onClick={() => { calcApy(status.input, status.stakedFor, 30) }}>30D</div>
                        </StyledStakedFor>
                        <StyledRatepanel>
                            <p style={{ color: theme.text3, fontSize: '0.7rem' }}>
                                ROI AT CURRENT RATES
                            </p>
                            <h4 style={{ color: theme.text1, fontSize: '1.2rem' }}>${status.output || 0}</h4>
                        </StyledRatepanel>
                    </div>
                </StyledApyModal>
            }
        </>
    );
};

export default forwardRef(FarmItem);

const StyledRatepanel = styled.div`
	border-radius: 1rem;
	border: 1px solid ${({ theme }) => (theme.text4)};
	padding: 1rem;
	width: 100%;
	margin: 1.5rem 0;
	background-color: ${({ theme }) => (theme.text5)};
	.input-row{
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: flex-end;
		input{
			background-color: transparent;
			border: none;
			text-align: right;
			color:  ${({ theme }) => (theme.text1)};
			:hover, :focus, :active{
				border: none;
				outline: none;
				box-shadow: none;
			}
		}
	}
`;


const StyledStakedFor = styled.div`
	border-radius: 1rem;
	margin: 8px 0;
	padding: 0;
	border: 1px solid ${({ theme }) => (theme.text5)};
	background-color: ${({ theme }) => (theme.text5)};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.item{
		color:  ${({ theme }) => (theme.secondary1)};
		cursor: pointer;
		font-weight: 500;
		font-size: 0.9rem;
		padding: 4px 1rem;
		:hover{
			color:  ${({ theme }) => (theme.text2)};
		}
		&.active{
			border-radius: 1rem;
			background-color:  ${({ theme }) => (theme.secondary1)};
			color:  ${({ theme }) => (theme.text1)};
		}
	}
`

const StyledUSD = styled.div`
	border-radius: 1rem;
	color: ${({ theme }) => (theme.secondary1)};
	font-size: 0.7rem;
	background-color:${({ theme }) => (theme.text5)};
	padding: 4px 1rem;
	cursor: pointer;
	:hover{
		background-color:${({ theme }) => (theme.text4)};
		color: ${({ theme }) => (theme.text1)};
	}
`

const StyledInputValue = styled.div`
	border-radius: 1rem;
	border: 1px solid ${({ theme }) => (theme.text4)};
	padding: 0.5rem;
	width: 100%;
	margin: 0.5rem 0;
	background-color: ${({ theme }) => (theme.text5)};
	.input-row{
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: flex-end;
		input{
			background-color: transparent;
			border: none;
			text-align: right;
			color:  ${({ theme }) => (theme.text1)};
			:hover, :focus, :active{
				border: none;
				outline: none;
				box-shadow: none;
			}
		}
	}
`;

const StyledFarmGridBody = styled.div`
	display: block;
	grid-template-columns: repeat(1 1fr);
	grid-gap: 20px;
	margin: 16px 0;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
`;

const StyledSmallText = styled.p`
	font-size: 0.8rem;
	color:   ${({ theme }) => (theme.text2)};
	margin-top: 1.5rem;
`;

const StyledApyModal = styled.div`
	position: fixed;
	left:0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	display: flex;
	z-index: 10000;
	flex-direction: column;
	justify-content: center;
	.overlay{
		position: fixed;
		left:0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
	}
	.content{
		margin-left: auto;
		margin-right: auto;
		min-width: 300px;
		border-radius: 1rem;
		z-index: 1000;
		background-color:  ${({ theme }) => (theme.bg1)};
		box-shadow: rgb(14 14 44 / 10%) 0px 20px 36px -8px, rgb(0 0 0 / 5%) 0px 1px 1px;
		padding: 1rem;
		@media (max-width: 768px) {
			width: 100%;
		}
	}
`;

const StyledContainer = styled.div<{ isExpand?: boolean; isSticky?: boolean }>`
	width: 100%;
	padding: 20px 10px 20px 16px;
	border-radius: 8px;
	cursor: ${({ isExpand }) => (isExpand ? 'auto' : 'pointer')};
	position: relative;
	@media (max-width: 768px) {
		padding: 15px 10px;
	}
`;


const StyledHeader = styled.div`
	display: grid;
	grid-template-columns: 5fr 3fr 3fr 3fr 3fr 3fr 1fr;
	grid-gap: 10px;
	text-decoration: none;
	flex: 1;
	> .indicator-icon {
		margin-left: auto;
		position: absolute;
		top: -14px;
		img + img {
			margin-left: 8px;
		}
	}
	.earn-info {
		display: flex;
		flex: 1;
	}
	.group-icon {
		display: flex;
	}
	@media (max-width: 1024px) {
		grid-template-columns: 12fr 1fr;
		.earn-info {
			flex-direction: column;
			justify-content: center;
			img {
				width: 30px!important;
				height: 30px!important;
			}
		}
	}
	
`;

const StyledHeaderStatus = styled.div`
	margin-left: 10px;
	flex: 1;
	white-space: no-wrap;
`;

const StyledHeaderIcon = styled.div`
	padding: 5px 0;
	display: flex;
	align-items: center;
	@media (max-width: 1024px) {
		padding: 5px 0;
	}
`;



const StylePendingRewards = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

const StyledHeaderIconWrapper = styled.div<{ marginRight?: string }>`
	display: flex;
	align-items: center;
	margin-right: ${({ marginRight }) => marginRight || '0'};
	color:	${({ theme }) => theme.text1};
`;

const StyledHeaderSubTitle = styled.div`
	font-size: 14px;
	display: block;
	color:	${({ theme }) => theme.text3};
	@media (max-width: 1024px) {
		font-size: 13px;
	}
`;

const StyledHeaderTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	color:	${({ theme }) => theme.primaryText1};
	@media (max-width: 1024px) {
		font-size: 13px;
	}
`;


const StyledHeaderDataValue = styled.div<{ highlight?: boolean }>`
	font-size: 16px;
	font-weight: 600;
	color: ${({ theme }) => (theme.text1)};
	@media (max-width: 1024px) {
		margin: 0px 0px 0px 10px;
		font-size: 11px;
	}
	.line {
		display: flex;
		align-items: center;
		font-size: 12px;
		margin-bottom: 3px;
		font-weight: 500;
		.field {
			color: #a2a2a2;
			margin-right: 10px;
		}
	}
`;

const StyledHeaderAction = styled.button<{ expanded?: boolean }>`
	width: 20px;
	margin-left: 10px;
	appearance: none;
	background: transparent;
	color: ${({ theme }) => theme.primary1};
	font-size: 18px;
	color: #ffffff;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: ease-in-out 100ms;
	text-decoration: none;
	border: none;
	@media (max-width: 1024px) {
		font-size: 14px;
		margin-left: 5px;
	}
`;


const StyledHeaderCell = styled.div<{ paddingLeft?: number; hiddenXs?: boolean }>`
	display: flex;
	align-items: center;
	padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft + 'px' : '')};
	@media (max-width: 768px) {
		display: ${({ hiddenXs }) => (hiddenXs ? 'none' : 'flex')};
	}
`;

const Button = styled.button`
	text-align: center;
	border-radius: 6px;
	outline: none;
	border: 1px solid transparent;
	text-decoration: none;
	display: flex;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	cursor: pointer;
	position: relative;
	z-index: 1;
	background-color: ${({ theme }) => theme.primary1};
	color: ${({ theme }) => theme.white};
	font-size: 16px;
	font-weight: 600;
	transition: ease-in-out 150ms;
	padding: 10px 22px;
	margin: 4px;
	&:hover {
		background-color: ${({ theme }) => theme.primary1};
	}
	&:disabled {
		background-color: rgb(164, 168, 179);
		color: ${({ theme }) => theme.text3};
		cursor: auto;
		box-shadow: none;
		border: 1px solid transparent;
		outline: none;
		opacity: 1;
	}
	.loader {
		margin-right: 5px;
		height: 20px;
	}
	&.btn2{
		background-color: transparent;
		border: 1px solid ${({ theme }) => theme.primary1};
		color: ${({ theme }) => theme.primary1};
		&:hover{
			border-color:${({ theme }) => theme.primary1};
			color:${({ theme }) => theme.primary1};
		}
	}
`;



const StyledControl = styled.div`
	display: flex;
	padding-bottom: 25px;
	@media (max-width: 768px) {
		flex-wrap: wrap;
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StyledSubheaderMobile = styled.div`
	display: none;
	@media (max-width: 768px) {
		margin-top: 15px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		font-size: 11px;
	}
`;


const StyledContent = styled.div`
	margin-top: 8px;
	padding-top: 25px;
	border-top: 1px dashed ${({ theme }) => theme.text3};
	@media (max-width: 1024px) {
		padding-top: 25px;
	}
`;

const StyledInnerContent = styled.div`
	padding: 0 40px;
	@media (max-width: 1024px) {
		padding: 0;
	}
`;


const StyledControlItem = styled.div`
	flex: 1;
	width: 100%;
	.group-button {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 62px;
	}
	:not(:first-child) {
		margin-left: 23px;
	}
	:not(:last-child) {
		margin-right: 23px;
	}
	@media (max-width: 768px) {
		:not(:first-child) {
			margin-left: 0px;
			margin-top: 40px;
		}
		:not(:last-child) {
			margin-right: 0px;
		}
	}
`;


const StyledLiquidity = styled.div`
    alignItems:center;
	margin-left: auto;
	a {
		padding: 0px;
		margin-top: 4px;
		margin-right: 20px;
		font-size: 14px;
		font-weight: 500;
		color: ${({ theme }) => theme.primary1};
		text-decoration: none;
		:hover {
			text-decoration: underline;
		}
	}
	@media (max-width: 1024px) {
		margin-left: 0;
		margin-bottom: 20px;
		width: 100%;
		a {
			flex: 1;
			padding: 0;
			text-align: center;
			margin-left: 0;
		}
	}
`;


const StyledClaimContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	font-size: 15px;
	font-weight: 500;
	color: #ffffff;
	border-top: 1px dashed ${({ theme }) => theme.text3};
	padding: 10px 10px 5px;
	.left {
	}
	.right {
		margin-left: auto;
		display: flex;
		align-items: center;
	}
	.amount {
		font-weight: 500;
		color:${({ theme }) => theme.secondary1};
		margin-left: 5px;
	}
	.symbol {
		font-weight: 500;
		color: #91908f;
	}
	.claim {
		margin-left: 10px;
		background: transparent;
		border: none;
		cursor: pointer;
		font-size: 15px;
		font-weight: 600;
		color: ${({ theme }) => theme.primary1};
		:disabled {
			color: #91908f;
			cursor: not-allowed;
		}
		&:not(:disabled):hover {
			text-decoration: underline;
		}
	}
	@media (max-width: 1024px) {
		flex-direction: column;
		.left {
			display: flex;
			align-items: center;
			a {
				white-space: nowrap;
			}
		}
		.right {
			margin: auto;
			flex-direction: column;
		}
	}
	@media (max-width: 768px) {
		justify-content: center;
		flex-direction: row;
	}
`;


const StyledTokenStaked = styled.div`
	font-size: 16px;
	font-weight: 500;
	display: flex;
	margin-right: 8px;
	align-items: center;
	a {
		font-size: 14px;
	}
	i {
		margin-left: 8px;
	}
	.stake-highlight {
		color:${({ theme }) => theme.primary2};
		margin-left: 10px;
		font-size: 14px;
	}
	@media (max-width: 1024px) {
		flex-direction: row;
		font-size: 14px;
		.stake-highlight {
			margin: 4px;
		}
	}
`;

const Balance = styled.div`
	margin-bottom: 5px;
	font-size: 14px;
	font-weight: normal;
	color: #91908f;
	.balance-click {
		cursor: pointer;
		display: inline-block;
		color: #fea430;
		font-weight: 500;
	}
	@media (max-width: 768px) {
		font-size: 14px;
	}
`;


const StyledFooter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 55px;
	flex-wrap: wrap;
	a {
		margin-right: auto;
		font-size: 14px;
		font-weight: 500;
		color: #fea430;
		cursor: pointer;
		:hover {
			text-decoration: underline;
		}
	}
	i {
		margin-left: 8px;
	}
`;


const StyledSubheaderCell = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 3px;
	color: ${({ theme }) => (theme.text2)};
`;


const StyledMinAPY = styled.div`
    font-size: 15px;
	display: flex;
	align-items: center;
	margin-bottom: 3px;
	color: ${({ theme }) => (theme.text2)};
`;