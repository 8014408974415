import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import React, { useMemo } from 'react'
import { Activity } from 'react-feather'
import styled from 'styled-components'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'
import { NetworkContextName } from '../../constants'
import useENSName from 'hooks/useENSName'
import { useWalletModalToggle } from 'state/application/hooks'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { shortenAddress } from 'utils'
import { Tooltip } from '@blockstack/ui'
import { StyledIcon } from 'components/styled-icon'

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`
const StyledHeading = styled.h1`
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff8a00),
    to(#e52e71)
  );
  background: linear-gradient(90deg, #0075c9, #D4418E);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  text-shadow: none;
  font-weight:700;
`;

function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
    return b.addedTime - a.addedTime
}

function Web3StatusInner() {
    const { account, error } = useWeb3React()

    const { ENSName } = useENSName(account ?? undefined) //"0xE6E2C266543Ee7a01dE3a61E8517B1A164a041Ef") // 

    const allTransactions = useAllTransactions()

    const sortedRecentTransactions = useMemo(() => {
        const txs = Object.values(allTransactions)
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
    }, [allTransactions])

    const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

    const hasPendingTransactions = !!pending.length
    const toggleWalletModal = useWalletModalToggle()

    if (account) {
        return (
            <button
                className="flex items-center px-1 text-sm font-medium text-gray-500 hover:text-gray-700 "
                id="web3-status-connected"
                onClick={toggleWalletModal}
            >
                {hasPendingTransactions ? (
                    <RowBetween>
                        <p>{pending?.length} Pending</p> <Loader stroke="white" />
                    </RowBetween>
                ) : (
                    <>
                        <span className="inline-block w-3 h-3 pt-2 mr-2 bg-green-400 border-2 border-white rounded-full"></span>
                        <p>{ENSName ? (<StyledHeading>{ENSName}</StyledHeading>) : shortenAddress(account)}</p>
                        <Tooltip
                            style={{ "zIndex": "100" }}
                            shouldWrapChildren={true}
                            label={`FNS is fully integrated with Scaryswap , use your .ftm primary domain to connect your wallet.`}
                        >
                            💾
                            {/* <StyledIcon as="QuestionMarkCircleIcon" size={5} className="block text-gray-400" /> */}
                        </Tooltip>
                    </>
                )}
            </button>
        )
    } else if (error) {
        return (
            <button
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700"
                id="connect-wallet"
                onClick={toggleWalletModal}
            >
                <NetworkIcon />
                <p>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</p>
            </button>
        )
    } else {
        return (
            <button
                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700"
                id="connect-wallet"
                onClick={toggleWalletModal}
            >
                Connect Wallet
            </button>
        )
    }
}

export default function Web3Status() {
    const { active, account } = useWeb3React()
    const contextNetwork = useWeb3React(NetworkContextName)

    const { ENSName } = useENSName(account ?? undefined) //"0xE6E2C266543Ee7a01dE3a61E8517B1A164a041Ef") //

    const allTransactions = useAllTransactions()

    const sortedRecentTransactions = useMemo(() => {
        const txs = Object.values(allTransactions)
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
    }, [allTransactions])

    const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
    const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

    if (!contextNetwork.active && !active) {
        return null
    }

    return (
        <>
            <Web3StatusInner />
            <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
        </>
    )
}
