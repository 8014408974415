import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBetween } from '../Row'
import { TYPE, CloseIcon } from '../../theme'
import { ButtonError } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useStakingContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'
import { AddNotification } from 'utils/views'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
    isOpen: boolean
    onDismiss: () => void
    stakingInfo: StakingInfo
}

export default function UnstakingModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
    const { account } = useActiveWeb3React()

    // monitor call to help UI loading state
    const addTransaction = useTransactionAdder()
    const [hash, setHash] = useState<string | undefined>()
    const [attempting, setAttempting] = useState(false)

    function wrappedOndismiss() {
        setHash(undefined)
        setAttempting(false)
        onDismiss()
    }

    const stakingContract = useStakingContract(stakingInfo?.stakingRewardAddress)

    async function onWithdraw() {
        if (stakingContract && stakingInfo?.stakedAmount) {
            setAttempting(true)
            await stakingContract
                .claimAndWithdraw()
                .then((response: TransactionResponse) => {
                    addTransaction(response, {
                        summary: `Withdraw deposited liquidity`
                    })
                    setHash(response.hash)
                })
                .catch((error: any) => {
                    setAttempting(false)
                    AddNotification("Widthraw Failed", "You haven’t passed the lock time yet", "warning")
                    console.log(error)
                })
        }
    }

    let error: string | undefined
    if (!account) {
        error = 'Connect Wallet'
    }
    if (!stakingInfo?.stakedAmount) {
        error = error ?? 'Enter an amount'
    }

    return (
        <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
            {!attempting && !hash && (
                <ContentWrapper gap="lg">
                    <RowBetween>
                        <TYPE.mediumHeader>Withdraw</TYPE.mediumHeader>
                        <CloseIcon onClick={wrappedOndismiss} />
                    </RowBetween>
                    {stakingInfo?.stakedAmount && (
                        <AutoColumn justify="center" gap="md">
                            <TYPE.body fontWeight={600} fontSize={36}>
                                {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
                            </TYPE.body>
                            <TYPE.body>Deposited liquidity:</TYPE.body>
                        </AutoColumn>
                    )}
                    {stakingInfo?.earnedAmount && (
                        <AutoColumn justify="center" gap="md">
                            <TYPE.body fontWeight={600} fontSize={36}>
                                {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
                            </TYPE.body>
                            <TYPE.body>Unclaimed PUMPKIN</TYPE.body>
                        </AutoColumn>
                    )}
                    <TYPE.subHeader style={{ textAlign: 'center' }}>
                        When you withdraw, your PUMPKIN is not claimed but your liquidity is removed from the mining pool.
                    </TYPE.subHeader>
                    <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
                        {error ?? 'Withdraw & Claim'}
                    </ButtonError>
                </ContentWrapper>
            )}
            {attempting && !hash && (
                <LoadingView onDismiss={wrappedOndismiss}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.body fontSize={20}>Withdrawing {stakingInfo?.stakedAmount?.toSignificant(4)} ScarySwap</TYPE.body>
                        <TYPE.body fontSize={20}>Claiming {stakingInfo?.earnedAmount?.toSignificant(4)} PUMPKIN</TYPE.body>
                    </AutoColumn>
                </LoadingView>
            )}
            {hash && (
                <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
                    <AutoColumn gap="12px" justify={'center'}>
                        <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
                        <TYPE.body fontSize={20}>Withdraw ScarySwap!</TYPE.body>
                        <TYPE.body fontSize={20}>Claimed PUMPKIN!</TYPE.body>
                    </AutoColumn>
                </SubmittedView>
            )}
        </Modal>
    )
}
