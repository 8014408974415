import React from 'react'
import styled from 'styled-components'
import background from "../../assets/images/l_bg.jpg";

const BgPanel = styled.div`
    background-image:url(${background});
    background-size: cover;
    width: 80%;
    max-width: 1280px;
    min-height:calc( 100vh - 150px );
    padding-top:150px;
    padding-inline:100px;
    
	@media (max-width: 1024px) {
        padding-top:50px;
        padding-inline:20px;
        min-height:calc( 100vh - 50px );
	}
`
const StyledHeaderDataValue = styled.div<{ highlight?: boolean }>`
	font-size: 30px;
	font-weight: 600;
	color: white;
    margin-bottom:50px;
	@media (max-width: 1024px) {
		font-size: 20px;
	}
	.line {
		display: flex;
		align-items: center;
		font-size: 12px;
		margin-bottom: 3px;
		font-weight: 500;
		.field {
			color: #a2a2a2;
			margin-right: 10px;
		}
	}
`;

export default function LaunchPad() {
    return (
        <BgPanel>
            <StyledHeaderDataValue>
                Benefits of launching on ScarySwap :
            </StyledHeaderDataValue>
            <StyledHeaderDataValue>
                1 - allow users to trade your token with zero fees and very low slippage
            </StyledHeaderDataValue>
            <StyledHeaderDataValue>
                2 - staking programs to provide liquidity for your token
            </StyledHeaderDataValue>
            <StyledHeaderDataValue>
                3 - free marketing campaigns for your project
            </StyledHeaderDataValue>
            <StyledHeaderDataValue>
                And Many more other benefits of launching on Scaryswap
                <br />
                Join the discord for more details
            </StyledHeaderDataValue>

        </BgPanel>
    )
}