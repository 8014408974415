
import { useEVMReverseResolverV1Contract, useRainbowTableV1Contract } from './useContract'
import { useEffect, useState } from 'react'
import clients from "../clients"
/**
 * Does a reverse lookup for an address to find its ENS name.
 * Note this is not the same as looking up an ENS name to find an address.
 */
export default function useENSName(address?: string): { ENSName: string | null | undefined; } {
    const [domain, setDomain] = useState(address)
    const EVMReverseResolverV1Contract = useEVMReverseResolverV1Contract()
    const RainbowTableV1Contract = useRainbowTableV1Contract();

    useEffect(() => {
        (async () => {
            try {
                const nameHash = await EVMReverseResolverV1Contract?.get(address);
                const nameSignal = await RainbowTableV1Contract?.lookup(nameHash.name);
                const cDomain = await clients.utils.decodeNameHashInputSignals(nameSignal);
                setDomain(cDomain);
            } catch (err: any) {
                setDomain(address);
                console.log("ENSERROR", err.message);
            }
        })()
    }, [address])

    return {
        ENSName: String(domain) === String(address) ? null : domain,
    }
}
